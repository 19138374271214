import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { CalendlyEventListener, PopupButton } from 'react-calendly'
import _ from 'lodash'

import { inputFields } from '../../templates/LeadGenPage'
import { gtagReportConversion } from '../../services/util'
import { GTAG_SEND_IDS, ZSF_GOOGLE_PIXEL_IDS } from '../../constant'
import './styles.scss'

const FormConfirmation = props => {
  const { data = {}, userData }  = props
  const calendlyLink = _.get(data, 'calendlyLink')
  const prefill =  {
    email: userData[inputFields.WORK_EMAIL],
    firstName: userData[inputFields.FIRST_NAME],
    lastName: userData[inputFields.LAST_NAME],
    name: `${userData[inputFields.FIRST_NAME]} ${userData[inputFields.LAST_NAME]}`,
    customAnswers:  {
      'a1': userData[inputFields.PHONE_NUMBER],
      'a2': userData[inputFields.COMPANY],
    }
  }

  const onEventScheduled = () => {
    gtagReportConversion(GTAG_SEND_IDS.CALENDLY_EVENT_SEND_ID)
    gtagReportConversion(ZSF_GOOGLE_PIXEL_IDS.CALENDLY_EVENT_SEND_ID)
    // After event schedule, redirect the user to home page after 3 seconds.
    setTimeout(() => window.location.href='/', 3000)
  }

  return (
    <div className='eg-form-confirmation'>
      <div className='logo-container'>
        <div className='logo'/>
      </div>
      <div className='thank' >{_.get(data, 'title')}</div>
      <div className='text'>
        {_.get(data, 'subtitle.subtitle')}
      </div>
      <PopupButton
        className="btn-cta"
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: false,
          hideGdprBanner: true,
          hideLandingPageDetails: false,
          primaryColor: '00a2ff',
          textColor: '4d5055'
        }}
        prefill={prefill}
        styles={{}}
        text="SCHEDULE DEMO"
        url={calendlyLink}
      />
      <Link className='later' to='/'>
        No, I'll do it later
      </Link>
      <CalendlyEventListener onEventScheduled={onEventScheduled} />
    </div>
  )
}

FormConfirmation.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  userData: PropTypes.object,
}

export default FormConfirmation
