import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import querystring from 'query-string'
import { globalHistory } from '@reach/router'
import { graphql } from 'gatsby'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import _ from 'lodash'

import MainLayout from '../../components/MainLayout'
import { Input } from '../../components/FormComponents'
import StatelessModal from '../../components/StatelessModal'
import FormConfirmation from '../../components/FormConfirmation'
import Loader from '../../components/Loader'
import { REGEXES } from '../../constant'
import { gtagReportConversion } from '../../services/util'
import { GTAG_SEND_IDS, ZSF_GOOGLE_PIXEL_IDS } from '../../constant'
import './styles.scss'

export const inputFields = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  WORK_EMAIL: 'work_email',
  PHONE_NUMBER: 'phone_number',
  COMPANY: 'company',
  // We are using field name `g-recaptcha-response` for recaptcha, according to form-spree doc.
  // |> https://help.formspree.io/hc/en-us/articles/360022811154
  CAPTCHA_TOKEN: 'g-recaptcha-response',
}

const initialState = {
  loading: false,
  success: false,
  error: false,
  data: null
}

function LeadGenPage(props) {
  const { content: contentfulData } = props.pageContext
  const { search } = globalHistory.location
  const queryParameterObj = querystring.parse(search)
  const email = _.get(queryParameterObj, 'email', '')
  const [hasCaptchaSubmitted, setHasCaptchaSubmitted] = useState(false)
  const [formState, setFormState] = useState(initialState)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const headerData = _.get(props, 'data.allContentfulHomeV3.edges[0].node')
  const imageUrl = _.get(contentfulData, 'image.file.url')
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      [inputFields.WORK_EMAIL]: email || ''
    },
  })

  useEffect(() => {
    register({ name: inputFields.CAPTCHA_TOKEN }, { required: 'Please verify that you are not a robot.' })
  }, [register])

  const onRecaptchaChange = (value) => {
    setHasCaptchaSubmitted(true)
    setValue(inputFields.CAPTCHA_TOKEN, value)
  }

  const onSubmit = (data) => {
    gtagReportConversion(GTAG_SEND_IDS.LEAD_GEN_SUBMISSION_SEND_ID)
    gtagReportConversion(ZSF_GOOGLE_PIXEL_IDS.LEAD_GEN_SUBMISSION_SEND_ID)
    setFormState({ ...formState, data, loading: true })
    fetch(process.env.GATSBY_LEAD_GEN_FORM_SUBMIT_URL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      setFormState({ ...formState, data, loading: false, success: true})
      setShowSuccessModal(true)
    }).catch(error => {
      setFormState({ ...formState, data, loading: false, error: true})
    })
  }

  const onSuccessModalClose = () => {
    setShowSuccessModal(false)
  }

  const data = watch()
  const captchaError = _.get(errors, `${inputFields.CAPTCHA_TOKEN}.message`)

  return (
    <MainLayout data={{...headerData, title: 'ZeroStorefront | Schedule Demo'}} headerClass='eg-press-header' >
      <div className='eg-lead-gen'>
        <div className='lead-left-part'>
          <div className='title'>
            {_.get(contentfulData, 'title')}
          </div>
          <div className='subtitle'>
            {_.get(contentfulData, 'subtitle')}
          </div>
          <img src={`${imageUrl}`} className='image' alt={'step logo'}/>
        </div>
        <div className='lead-right-part' id='eg-demo-section'>
          <div className='form-title'>
            {_.get(contentfulData, 'formHeader')}
          </div>
          <form className='form' onSubmit={handleSubmit(onSubmit)} >
            <Input
              className='form-input'
              placeholder='First Name'
              label='First Name'
              name={inputFields.FIRST_NAME}
              value={data[inputFields.FIRST_NAME]}
              ref={register({required: 'First name is required.' })}
              errorMessage={_.get(errors, `${inputFields.FIRST_NAME}.message`) }
            />
            <Input
              className='form-input'
              placeholder='Last Name'
              label='Last Name'
              name={inputFields.LAST_NAME}
              value={data[inputFields.LAST_NAME]}
              ref={register({required: 'Last name is required.' })}
              errorMessage={_.get(errors, `${inputFields.LAST_NAME}.message`) }
            />
            <Input
              className='form-input'
              placeholder='john.doe@gmail.com'
              label='Work Email'
              type='email'
              name={inputFields.WORK_EMAIL}
              value={data[inputFields.WORK_EMAIL]}
              ref={register({required: true, pattern: REGEXES.EMAIL})}
              errorMessage={_.get(errors, `${inputFields.WORK_EMAIL}`) && 'Please enter valid email.'}
            />
            <Input
              className='form-input'
              placeholder='(123) 456-7890'
              label='Phone Number'
              type='number'
              name={inputFields.PHONE_NUMBER}
              value={data[inputFields.PHONE_NUMBER]}
              ref={register({
                required: true,
                pattern: REGEXES.PHONE_NUMBER,
                maxLength: 10},
              )}
              errorMessage={_.get(errors, `${inputFields.PHONE_NUMBER}`) && 'Please enter valid phone number.'}
            />
            <Input
              className='form-input'
              placeholder='Restaurant name'
              label='Restaurant'
              name={inputFields.COMPANY}
              value={data[inputFields.COMPANY]}
              ref={register({required: 'Restaurant name is required.' })}
              errorMessage={_.get(errors, `${inputFields.COMPANY}.message`) }
            />

            <ReCAPTCHA
              className="g-recaptcha"
              sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_KEY}
              onChange={onRecaptchaChange}
            />
            {captchaError && !hasCaptchaSubmitted && <div className='captcha-error'> {captchaError} </div>}
            {formState.loading
              ? <Loader className='eg-form-loader' />
              : (
                <button
                  className={`btn-cta ${hasCaptchaSubmitted ? '' : 'disable-button'}`}
                  type='submit'
                >
                  {_.get(contentfulData, 'formCta')}
                </button>
              )
            }
          </form>
        </div>
        {showSuccessModal && (
          <StatelessModal onClose = { onSuccessModalClose } backGroundClassName='background-modal'>
            <FormConfirmation
              data={_.get(contentfulData, 'leadsConfirmation')}
              onClose={onSuccessModalClose}
              userData={formState.data}
            />
          </StatelessModal>
        )}
      </div>
      {/* <DemoFooter /> */}
    </MainLayout>
  )
}

export const LeadGenPageQuery = graphql`
  query LeadGenPage {
    allContentfulHomeV3 {
      edges {
        node {
          title
          hero {
            cta
          }
        }
      }
    }
  }
  `

LeadGenPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default LeadGenPage
